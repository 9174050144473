<template>
  <div class="d-flex align-center">
    <div class="d-flex">
      <v-switch v-model="isApply" label="驗證" disabled class="mr-2"></v-switch>
      <v-btn v-if="!isApply" @click="verify" color="primary" :disabled="disableSendVerify"
        >通知審核通過</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formKey: String,
  },
  data: () => ({}),
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
    isApply() {
      if (!this.formData) return false;
      return (
        this.formData.status === this.$providerApplyStatus.STATUS_APPLY
      );
    },
    providerId() {
      if (!this.formData) return "";
      return this.formData.provider_id;
    },
    disableSendVerify() {
      return false;
    },
  },
  methods: {
    // async sendVerify() {
    //   this.$store.dispatch('loading/active')
    //   try {
    //     await this.$api.collection.providerApi.sendVerify(this.providerId);
    //     this.$snotify.success(null, this.$t("provider.send-verify.success"));
    //   } catch (err) {
    //     console.error(err);
    //     this.$snotify.error(null, this.$t("provider.send-verify.error"));
    //   } finally {
    //     this.$store.dispatch('loading/close')
    //   }
    // },
    async verify() {
      this.$store.dispatch('loading/active')
      try {
        await this.$api.collection.providerApi.verify(this.providerId);
        this.$store.dispatch(`form/${this.formKey}/setDataColumn`, {key: 'status', value: this.$providerApplyStatus.STATUS_APPLY})
        this.$snotify.success(null, '供應商通過審核');
      } catch (err) {
        console.error(err);
        this.$snotify.error(null, '供應商通過審核失敗');
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
  },
};
</script>